import React from "react";
import { Button, Input, Container, Row, Col, Form } from "reactstrap";
import styled from "styled-components";
import Call from "./Call";
import Typewriter from "typewriter-effect";
import { BrowserView, MobileView } from "react-device-detect";

const WrapperStyle = styled.div`
    width: 100%;
    height: 100%;

    .join-form {
        vertical-align: middle;
        justify-content: center;
        margin-top: 1rem;
    }
`;

class Wrapper extends React.Component {
    state = {
        password: "",
        name: "",
        call: false,
    };

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    join = (event) => {
        event.preventDefault();
        this.setState({
            call: true,
        });
    };

    leftCall = () => {
        this.setState({
            call: false,
            password: "",
        });
    };

    renderCall = () => {
        return (
            <Call
                roomName={this.props.roomName}
                displayName={this.state.name}
                password={this.state.password}
                subject={this.props.subject}
                leftCall={this.leftCall}
            />
        );
    };

    renderDefault = () => {
        return (
            <WrapperStyle>
                <Container>
                    <Row>
                        <Col>
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter
                                        .typeString("Hello. 👋")
                                        .pauseFor(20000)
                                        .deleteAll()
                                        .pauseFor(1000)
                                        .typeString("Welcome.")
                                        .start();
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="join-form">
                        <BrowserView>
                            <Form inline>
                                <Col sm={8}>
                                    <Input
                                        name="name"
                                        id="name"
                                        placeholder="Your name..."
                                        value={this.state.name}
                                        onChange={this.handleChange}
                                        autoComplete={"off"}
                                    />
                                </Col>
                                {/* <Col sm={5}>
                                <Input
                                    name="password"
                                    id="password"
                                    placeholder="Room password..."
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    autoComplete={"off"}
                                />
                            </Col> */}
                                <Col sm={2}>
                                    <button
                                        type="submit"
                                        className={"join btn btn-secondary"}
                                        onClick={this.join}
                                        color="secondary"
                                        disabled={this.state.name === ""}
                                    >
                                        Join!
                                    </button>
                                </Col>
                            </Form>
                        </BrowserView>
                        <MobileView>
                            <h2>
                                Not yet working on mobile, sorry - use a
                                laptop/desktop
                            </h2>
                        </MobileView>
                    </Row>
                </Container>
            </WrapperStyle>
        );
    };

    render() {
        if (!this.state.call) {
            return this.renderDefault();
        } else {
            return this.renderCall();
        }
    }
}

Wrapper.defaultProps = {
    roomName: "the-room-name-long-enough-not-to-be-guessable",
    subject: "Hello.",
};

export default Wrapper;
